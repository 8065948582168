import React from "react"
import { graphql } from "gatsby"
import "./highlight.module.scss"

import { RichTextContent } from "_types/RichText"
import { Content } from "components/content/Content"

import { Counter } from "components/highlights/Counter"
import { SingleCounter } from "components/highlights/Counter/counter-types"

import { HighlightIcon } from "./HighlightIcon"
import { HighlightIconTypes } from "./highlight-types"

interface HighlightProps {
  baseClassName?: string
  caption?: RichTextContent
  counter: SingleCounter
  icon: HighlightIconTypes
  title?: string
}

export const Highlight = ({
  baseClassName,
  caption,
  counter,
  icon,
  title,
}: HighlightProps) => {
  const { iconDirection, image, svg } = icon

  const counterEndValue = counter.endValue
  const type = counterEndValue ? "fr-counter" : "fr-highlight"

  const hasCounter = counterEndValue !== null ? true : false,
    hasImg = image && (!svg || svg.trim().length === 0),
    hasSvg = svg && svg.trim().length !== 0,
    hasIcon = hasImg || hasSvg,
    hasIconCounter = hasCounter && hasIcon

  const getRowTypeClass = () => {
    switch (true) {
      case hasIconCounter:
        return `${type}__row--counter`
      case hasIcon:
        return `${type}__row--icon`
      case !hasIcon:
        return `${type}__row--text`
      default:
        return ""
    }
  }

  const outputClasses = (str: string) => {
    let classes: string = `${type}__${str}`
    if (baseClassName) classes += ` ${baseClassName}__${str}`
    return classes
  }

  const CounterItem = () => {
    return counter ? (
      <Counter
        preventIconBouncing
        baseClassName={baseClassName}
        values={counter}
      />
    ) : (
      <></>
    )
  }

  const HighlightSvg = () => {
    return hasSvg ? (
      <HighlightIcon
        className={outputClasses("svg")}
        content={svg}
        type="svg"
      />
    ) : (
      <></>
    )
  }

  const HighlightImg = () => {
    return hasImg ? (
      <HighlightIcon
        className={outputClasses("img")}
        content={image}
        type="image"
      />
    ) : (
      <></>
    )
  }

  const highlightClasses = [
    outputClasses("child"),
    `${type}__icon-direction--${iconDirection}`,
  ].join(" ")

  const highlightRowClasses = [outputClasses("row"), getRowTypeClass()].join(
    " "
  )

  return (
    <div className={highlightClasses}>
      {(hasCounter || hasIcon) && (
        <div className={highlightRowClasses}>
          {(iconDirection === "left" || iconDirection === "center") && (
            <>
              <HighlightImg />
              <HighlightSvg />
              <CounterItem />
            </>
          )}
          {iconDirection === "right" && (
            <>
              <CounterItem />
              <HighlightImg />
              <HighlightSvg />
            </>
          )}
        </div>
      )}
      <div className={outputClasses("content")}>
        {title && <h4 className={outputClasses("title")}>{title}</h4>}
        {caption && caption?.value !== "<p><br></p>" && (
          <div className={outputClasses("caption")}>
            <Content content={caption} />
          </div>
        )}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HighlightFragment on kontent_item_highlight {
    system {
      name
    }
    id
    elements {
      caption {
        value
        links {
          link_id
          url_slug
        }
      }
      image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      svg_icon {
        value
      }
      heading {
        value
      }
      counter_start_value {
        value
      }
      counter_end_value {
        value
      }
      special_character {
        value {
          name
        }
      }
      animated_counter {
        value {
          codename
        }
      }
    }
  }
`
