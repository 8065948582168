import React from "react"

import { SingleHighlight } from "../highlights-types"
import { SingleCounterHighlight } from "components/highlights/SingleCounterHighlight"
import { useInView } from "react-intersection-observer"
import "./hero-highlights.module.scss"

interface HeroHighlightsProps {
  highlights: SingleHighlight[]
}

export const HeroHighlights = ({ highlights }: HeroHighlightsProps) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  })

  return (
    <div className="fr-hero__highlights__wrapper" ref={ref}>
      {highlights.slice(0, 3).map(({ elements, id }) => {
        elements.caption = undefined
        return (
          <SingleCounterHighlight
            baseClassName="fr-hero__highlights"
            elements={elements}
            iconDirection="right"
            inView={inView}
            key={id}
          />
        )
      })}
    </div>
  )
}
