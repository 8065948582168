import React from "react"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { useTrackingNumberModal } from "hooks/useTrackingNumberModal"
import { TrackingNumberModal } from "components/modal/TrackingNumberModal/TrackingNumberModal"
import { RichTextContent } from "_types/RichText"
import { ImageBuilder } from "_types/AssetsImage"
import { ButtonBuilder } from "_types/Button"
import { TrackingButton } from "components/tracking-button"
import { Image } from "components/image/Image"
import { ButtonParser } from "components/button/ButtonParser"
import { SingleHighlight } from "components/highlights/highlights-types"
import { HeroHighlights } from "components/highlights/Hero/HeroHighlights"
import { GenerateVariationPrefixes } from "./_types/hero-classes-types"
import { useMainOfficeNumber } from "hooks/useMainOfficeNumber"
import { AnchorNumber } from "components/anchor-number/AnchorNumber"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { Button } from "components/button"
import { getPhoneIcon } from "assets/helper/icons"

interface HeroContentProps {
  baseClassName: GenerateVariationPrefixes
  buttons?: ButtonBuilder[]
  hasAlwaysOfficeNumber?: boolean
  heading?: string
  highlights?: SingleHighlight[]
  secondaryImage?: ImageBuilder
  subHeading?: RichTextContent
  tagline?: RichTextContent
}

export const HeroContent = ({
  baseClassName,
  buttons,
  hasAlwaysOfficeNumber = false,
  heading,
  highlights,
  secondaryImage,
  subHeading,
  tagline,
}: HeroContentProps) => {
  const { mainOfficeNumber } = useMainOfficeNumber({})
  const {
    trackingNumbers,
    customTrackingNumberCtaButtonText,
  } = useGeneralSettings()

  const trackingNumberCTAText =
    customTrackingNumberCtaButtonText || "Call Today"

  const { handleOpenModal, isModalOpen } = useTrackingNumberModal()

  const hasButtons = buttons && buttons?.length > 0

  return (
    <>
      {heading && <h1 className={`${baseClassName}__heading`}>{heading}</h1>}

      {subHeading && subHeading.value !== "<p><br></p>" && (
        <div className={`${baseClassName}__sub-heading`}>
          <RichTextElement value={subHeading.value} />
        </div>
      )}

      {tagline &&
        tagline?.value !== "" &&
        tagline?.value !== "<p><br></p>" &&
        (tagline.type === "rich_text" ? (
          <div className={`${baseClassName}__tagline`}>
            <RichTextElement value={tagline.value} />
          </div>
        ) : (
          <div className={`${baseClassName}__tagline`}>
            <p>{tagline.value}</p>
          </div>
        ))}

      {highlights && highlights.length > 0 && (
        <div className={`${baseClassName}__highlights`}>
          <HeroHighlights highlights={highlights} />
        </div>
      )}

      {hasButtons && (
        <div className={`${baseClassName}__buttons`}>
          <div className="u-buttons-group">
            <ButtonParser
              buttons={buttons}
              ctaColorVariantOutline="outline-primary"
              navigationColorVariantOutline="outline-accent"
            />
          </div>
          {mainOfficeNumber && trackingNumbers.length < 2 && (
            <span className={`${baseClassName}__contact-number-container`}>
              <span>Or Call:</span>
              <AnchorNumber
                baseClassName={`${baseClassName}-tracking`}
                contactNumber={mainOfficeNumber}
                showIcon={false}
                extraClassNames="button--secondary-text-link"
              />
            </span>
          )}
          {trackingNumbers.length >= 2 && (
            <>
              <span className={`${baseClassName}__contact-number-container`}>
                <TrackingButton showIcon={false} />
              </span>
            </>
          )}
        </div>
      )}

      {!hasButtons && hasAlwaysOfficeNumber && (
        <div className={`${baseClassName}__buttons`}>
          {mainOfficeNumber && (
            <span className={`${baseClassName}__contact-number-container`}>
              <span>Or Call:</span>
              <AnchorNumber
                baseClassName={`${baseClassName}-tracking`}
                contactNumber={mainOfficeNumber}
                showIcon={false}
                extraClassNames="button--secondary-text-link"
              />
            </span>
          )}
        </div>
      )}

      {secondaryImage && (
        <div className={`${baseClassName}__secondary-image-container`}>
          <Image
            image={secondaryImage}
            className={`${baseClassName}__secondary-image`}
          />
        </div>
      )}
      {trackingNumbers.length >= 2 && (
        <>
          <Button
            variant="flashy-icon"
            extraClassNames="secondary-header__button"
            onClick={handleOpenModal}
          >
            {getPhoneIcon()}
            {trackingNumberCTAText}
          </Button>
        </>
      )}
      {trackingNumbers.length >= 2 && isModalOpen && (
        <div className={`fr-tracking-number-modal`}>
          <TrackingNumberModal />
        </div>
      )}
    </>
  )
}
