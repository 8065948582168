import React from "react"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import { Layout } from "components/layout"
import { SEO } from "components/seo/seo"
import { SingleReview } from "components/reviews/SingleReview/SingleReview"
import { ReviewList } from "components/reviews/ReviewList"
import { GenericHero } from "components/hero/GenericHero/GenericHero"
import { BlocksWrapper } from "components/blocks-wrapper/BlocksWrapper"
import { BreadcrumbsGroup } from "components/breadcrumbs"

type PageContext = PageProps["pageContext"] & {
  breadcrumb: BreadcrumbsGroup
  linkedPagesUrlPathCodex: Record<string, string>
  slug: string
}

interface ReviewsPageProps extends PageProps {
  pageContext: PageContext
  data: any
}

const ReviewsPage: React.FC<ReviewsPageProps> = ({ location, pageContext }) => {
  const { pathname, hash } = location
  const data = useStaticQuery(getReviewsQuery)
  const { banner, seo_metadata__meta_title, seo_metadata__meta_description } =
    data.allKontentItemPage.nodes[0].elements

  const { linkedPagesUrlPathCodex, slug } = pageContext

  return (
    <Layout
      location={slug}
      pathName={pathname}
      hash={hash}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
    >
      <SEO
        title={seo_metadata__meta_title.value}
        description={seo_metadata__meta_description.value}
      />
      <GenericHero banner={banner.value[0].elements} />
      <BlocksWrapper>
        <ReviewList />
        <SingleReview />
      </BlocksWrapper>
    </Layout>
  )
}

const getReviewsQuery = graphql`
  query pestroutesReviews {
    allKontentItemPage(
      filter: { elements: { slug: { value: { eq: "reviews" } } } }
    ) {
      nodes {
        elements {
          seo_metadata__canonical_link {
            value
          }
          seo_metadata__meta_description {
            value
          }
          seo_metadata__meta_title {
            value
          }
          banner {
            value {
              ...HeroBannerFragment
            }
          }
          global_footer {
            value {
              ...GlobalFooterFragment
            }
          }
        }
      }
    }
  }
`

export default ReviewsPage
