import React, { useEffect, useState } from "react"
import { SingleCounter } from "./counter-types"
import "./counter.module.scss"

interface CounterProps {
  baseClassName?: string
  preventIconBouncing?: boolean
  values: SingleCounter
}

export const Counter = ({
  baseClassName,
  preventIconBouncing = false,
  values,
}: CounterProps) => {
  const { endValue, inView, isAnimated, specialCharacter, startValue } = values

  const [counter, setCounter] = useState(() =>
    isAnimated ? startValue : endValue
  )

  const outputClasses = (str: string) => {
    let classes: string = `fr-counter__${str}`

    if (baseClassName) classes += ` ${baseClassName}__${str}`

    return classes
  }

  useEffect(() => {
    if (!isAnimated) return
    if (!endValue) return

    let mounted = true

    const countdown = (startValue: number, endValue: number) => {
      const count = startValue
      const increment = endValue / 250

      if (count === endValue || count > endValue) {
        setCounter(endValue)
        return
      }

      const updatedCounter = Math.ceil(count + increment)
      setCounter(updatedCounter)
    }

    if (!inView) return
    if (counter === endValue) return

    setTimeout(() => {
      if (mounted) countdown(counter!, endValue)
    }, 3)

    return () => {
      mounted = false
    }
  }, [counter, endValue, inView, isAnimated, startValue])

  const fadeInSpecialCharacterClass =
    counter === endValue ? "fr-counter__special-character--fade-in" : ""

  const specialCharacterClass = outputClasses("special-character")

  return (
    <p className={outputClasses("number")}>
      {/* Hidden element to set animated counter width to prevent icon bouncing */}
      {isAnimated && preventIconBouncing && (
        <span className={outputClasses("number--hidden")} aria-hidden="true">
          {endValue}
          {specialCharacter && (
            <span className={outputClasses("special-character--visible")}>
              {specialCharacter}
            </span>
          )}
        </span>
      )}
      <span className={isAnimated ? outputClasses("number--absolute") : ""}>
        {counter}
        {specialCharacter && (
          <span
            className={`${specialCharacterClass} ${fadeInSpecialCharacterClass}`}
          >
            {specialCharacter}
          </span>
        )}
      </span>
    </p>
  )
}
