import React from "react"
import { Highlight } from "components/highlights/Highlight"
import { SingleHighlightElements } from "components/highlights/highlights-types"

interface HighlightsProps {
  baseClassName?: string
  elements: SingleHighlightElements
  iconDirection?: "left" | "center" | "right"
  inView: boolean
}

export const SingleCounterHighlight = ({
  baseClassName,
  elements,
  iconDirection = "center",
  inView,
}: HighlightsProps) => {
  const {
    animated_counter,
    caption,
    counter_end_value,
    counter_start_value,
    heading,
    image,
    special_character,
    svg_icon,
  } = elements

  const hasCounter = counter_end_value?.value !== null,
    directionOverride = hasCounter ? "left" : iconDirection

  const counterVals = {
    endValue: counter_end_value?.value,
    startValue: counter_start_value?.value,
    inView: inView,
    isAnimated: animated_counter?.value[0]?.codename === "yes",
    specialCharacter: special_character?.value[0]?.name,
  }

  const iconVals = {
    iconDirection: directionOverride,
    ...(image?.value[0] && { image: image?.value[0] }),
    ...(svg_icon?.value && { svg: svg_icon?.value }),
  }

  return (
    <Highlight
      baseClassName={baseClassName}
      caption={caption}
      counter={counterVals}
      icon={iconVals}
      title={heading?.value}
    />
  )
}
