import React from "react"
import { useColorsOverride } from "hooks/color-override/useColorsOverride"
import { useBackgroundImage } from "hooks/useBackgroundImage"
import { GenerateVariationPrefixes } from "../_types/hero-classes-types"

const CONTAINER_SIZE = {
  default: "fr-container",
  large: "fr-container fr-container--large",
  medium: "fr-container fr-container--medium",
  small: "fr-container fr-container--small",
  none: "",
}

type ContainerSize = keyof typeof CONTAINER_SIZE

interface BannerWrapperProps {
  backgroundContainerSize?: ContainerSize
  backgroundImageComponent?: React.ReactNode
  backgroundImageUrl?: string
  children: React.ReactNode
  containerRef?: React.RefObject<HTMLDivElement>
  containerSize?: ContainerSize
  contentClassName?: string
  prefix: GenerateVariationPrefixes
  prefixModifierClassName?: string
  wrapperClassName?: string
}

export const BannerWrapper = ({
  backgroundContainerSize = "none",
  backgroundImageComponent,
  backgroundImageUrl,
  children,
  containerRef,
  containerSize = "default",
  contentClassName = "",
  prefix,
  prefixModifierClassName = "",
  wrapperClassName = "",
}: BannerWrapperProps) => {
  const backgroundImageCSSVariable = useBackgroundImage({
    backgroundImageUrl,
    variable: "hero",
  })

  const colorAreaOverride = backgroundImageUrl ? "image-banner" : "text-banner"
  const { backgroundOverlayBeforeOverride } =
    useColorsOverride(colorAreaOverride)

  return (
    <div
      ref={containerRef}
      className={`${prefix} ${prefixModifierClassName} ${wrapperClassName}`}
    >
      {backgroundImageUrl && (
        <div
          className={`${prefix}__background-image ${backgroundOverlayBeforeOverride}`}
          style={backgroundImageCSSVariable}
        />
      )}
      <div className={`${CONTAINER_SIZE[containerSize]} ${contentClassName}`}>
        {children}
      </div>
      {backgroundImageComponent && (
        <div
          className={`${CONTAINER_SIZE[backgroundContainerSize]} ${prefix}__background-component`}
        >
          {backgroundImageComponent}
        </div>
      )}
    </div>
  )
}
