import React from 'react';
import { Image } from 'components/image/Image';
import { ImageBuilder } from '_types/AssetsImage';

interface HiglightIconProps {
  className: string;
  content: ImageBuilder | string;
  type: 'image' | 'svg';
}

export const HighlightIcon = (
  {
    className,
    content,
    type
  }: HiglightIconProps) => {

  switch (type) {
    case 'image':
      const image = content as ImageBuilder;

      return <>
        <Image
          alt={ image.description }
          className={ className }
          height={ image.height }
          image={ image }
          objectFit="contain"
          objectPosition="center"
          width={ image.width }
        />
      </>;
    case 'svg':
      const svg = content as string;
      return <>
        <div
          className={ className }
          contentEditable="false"
          dangerouslySetInnerHTML={{ __html: svg }}
        />
      </>
  }
}
